import React, {useState} from "react";
import PropTypes from "prop-types";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import Currency from "components/Core/Currency";
import styled from "styled-components";
import {Button} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {crudFunctions, aceptarSolicitud, rechazarSolicitud} from "api";
import {TIPO_UNIDAD, MERCANCIA, UNIDAD, CONDUCTOR, SOLICITUD} from "api/TYPES";
import {Address, Date, Ref} from "components/Core";
import {Select, Ref as RefSelect} from "components/FormComponents";
const Title = styled.div`
	color:var(--colors-primary);
	font-size:1.2em;
`;
const Subtitle = styled.div`
	color:var(--colors-gray);
	font-size:.8em;
`;
// const Column = styled.td`
// 	color:var(--colors-gray);
// 	font-size:.8em;
// 	text-align:right;
// `;
// const Column = styled.td`
// 	color:var(--colors-secondary);
// 	font-weight:bold;
// 	text-align:left;
// `;
const Row = styled.div`
	border-bottom: solid 1px #4895D3;
	display: flex;
	align-items: center;
	padding: 10px;
`;
const Column = styled.div`
	flex: 50%;
`;
const ColumnLabel = styled.div`
	flex: 50%;
	font-weight:bold;
`;

const Precio = styled.div`
	background:#84B2E3;
	color:white;
	font-size:1.3em;
	width: 200px;
	margin: auto;
	line-height: 40px;
`;
const Table = styled.table`
	margin:auto;
	margin-top:15px;
`
const Container = styled.div`
	text-align:center;
	margin:auto
`;
const StyledSelect = styled(Select)`
	width:200px;
`;
const Solicitud = props => {
	const queryClient = useQueryClient();
	const [step, setStep] = useState(1);
	const [maniobra, setManiobra] = useState("");
	// const actionsTipoUnidad = crudFunctions(TIPO_UNIDAD);
	// const {data:unidades} = useQuery(TIPO_UNIDAD.name, actionsTipoUnidad.getAll);

	// const actionsMercancia = crudFunctions(MERCANCIA);
	// const {data:mercancias} = useQuery(MERCANCIA.name, actionsMercancia.getAll);

	const { data: resultRechazar, status: statusRechazar, mutate: rechazar } = useMutation(rechazarSolicitud, {
		onSuccess:()=>(queryClient.invalidateQueries(SOLICITUD.name),props.closeModal())
	});

	const [error, setError] = React.useState();
	const aceptar = () => {
		if(maniobra==="" && props.data?.extras?.maniobra?.value) setError("Selecciona si deseas aceptar la maniobra");
		else{
			setError(null)
			setStep(2);
		}
	}
	return <>
		<div className="text-gray font-black  text-right" onClick={()=>props.closeModal()}>x</div>
		{step===1 && <div className="flex flex-col gap-5 p-7">
			<div className="text-blue font-black text-3xl text-center">Nueva solicitud</div>
			<div className="text-gray text-xs mx-5 text-center">Cuentas con 30 minutos para aceptar la solicitud antes de que alguien más la responda</div>

			{props.data?.saas ? <></>:<div className="bg-orange flex flex-col gap-5 md:gap-0 md:flex-row justify-between px-5 md:px-10 py-5 items-center text-white rounded-lg mx-5">
				<div className="gap-5">
					<div className="font-bold">Detalles de pago</div>
					<div className="grid grid-cols-2 gap-x-5 items-center">
						<div className="text-xs">Precio base</div>
						<div className="font-bold"><Currency value={props.data?.pago?.base_t} /></div>
						
						<div className="text-xs">IVA</div>
						<div className="font-bold"><Currency value={props.data?.pago?.total_t*.16} /></div>
					</div>

				</div>
							
				<div className="flex flex-col h-full">
					<div className="text-xl">Total</div>
					<div className="text-3xl font-black"><Currency value={props.data?.pago?.total_t*1.16} /></div>
				</div>

			</div>}
			<div className="grid grid-cols-1 md:grid-cols-3 items-center">
				<div>
					<div className="text-2xl text-blue font-black">D{props.data?.id}</div>
					<div className="text-gray text-xs">Tipo de camión:</div>
					<div className="text-blue font-black"><Ref dataType={TIPO_UNIDAD} value={props.data.tipo_unidad} dataKey="nombre" /></div>
				</div>
				<div>
					<div className="text-gray text-xs">Fecha y hora de carga:</div>
					<div className="text-blue font-black"><Date date={props.data?.origen?.fecha} /></div>

					<div className="text-gray text-xs">Fecha y hora de descarga:</div>
					<div className="text-blue font-black"><Date date={props.data?.destino?.fecha} /></div>
				</div>
				<div>
					<div className="text-gray text-xs">Tipo de mercancia:</div>
					<div className="text-blue font-black">{props.data.tipo_mercancia}</div>

					<div className="text-gray text-xs">Peso total apróx (KG):</div>
					<div className="text-blue font-black">{props.data.peso_total}</div>

					<div className="text-gray text-xs">Num. de paquetes:</div>
					<div className="text-blue font-black">{props.data?.num_paquetes}</div>
				</div>

			</div>
			<hr className="text-gray" />
			<div>
				<div className="text-gray text-xs">Lugar de origen:</div>
				<div className="text-blue font-black"><Address address={props.data?.origen?.direccion} /></div>
				

				{props.data?.paradas?.map((p, idx)=><>
					<hr className="text-gray my-2" />
					<div className="text-gray text-xs">Fecha y hora de parada {idx+1}:</div>
					<div className="text-blue font-black"><Date date={p?.fecha} /></div>

					<div className="text-gray text-xs">Dirección de parada {idx+1}:</div>
					<div className="text-blue font-black"><Address address={p.direccion} /></div>
					{p.especificaciones && <>
						<div className="text-gray text-xs">Especificaciones de parada {idx+1}:</div>
						<div className="text-blue font-black">{p.especificaciones}</div>
					</>}
					
				</>)}
				<hr className="text-gray my-2" />

				<div className="text-gray text-xs">Lugar de destino:</div>
				<div className="text-blue font-black"><Address address={props.data?.destino?.direccion} /></div>
				{props.data?.destino?.especificaciones && <>
					<div className="text-gray text-xs">Especificaciones de destino</div>
					<div className="text-blue font-black">{props.data?.destino?.especificaciones}</div>
				</>}
				<div className="text-gray text-xs">Cita:</div>
				<div className="text-blue font-black">{props.data?.destino?.cita?.value ? "Si" : "No"}</div>

				{props.data?.destino?.cita?.value && <>
					<div className="text-gray text-xs">Fecha de cita:</div>
					<div className="text-blue font-black"><Date date={props.data?.destino?.cita?.fecha} /></div>
				</>}
			
			</div>
			<div>
				{props.data?.extras?.maniobra?.value && <Row>
					<div className="text-gray text-xs">Maniobra</div>
					<div className="text-blue font-black">{props.data?.extras?.maniobra?.tipo==="ambas"?"Carga y descarga":props.data?.extras?.maniobra?.tipo} - no. personas: {props.data?.extras?.maniobra?.num_personas} - ${props.data?.pago?.extras?.find(e=>e.tipo==="maniobra")?.precio_t}</div>
					
					<Select options={[{value:"", label:"Sin selección"},{value:false, label:"Rechazar maniobra"}, {value:true, label:"Aceptar maniobra"}]} value={maniobra} onChange={setManiobra} />

					
				</Row>}
			</div>
			<div>
				{error && 	<Alert severity="error">{error}</Alert>}

				<div style={{marginTop:30}} className="flex justify-center">
					{(props.data?.saas && props.data?.id_transportista ) ? <>
						<Button variant="contained" color="primary" onClick={aceptar}>Aceptar</Button>
					</>:<div className="flex gap-5 flex-col md:flex-row">
						<button className={`rounded-3xl border text-orange border-orange w-40 px-5 py-2 `} onClick={()=>rechazar(props.data?._id)}>Rechazar</button>
						<button className={`rounded-3xl bg-orange text-white w-40 px-5 py-2 `} onClick={aceptar}>Aceptar</button>
					</div>}
				</div>
			</div>
		</div>}
		{step===2 &&<>
			<AceptarSolicitud maniobra={maniobra} tipo_unidad={props.data?.tipo_unidad} id_solicitud={props.data?._id} closeModal={props.closeModal} />
		</>}
	</>;

};
export default Solicitud;



function AceptarSolicitud({id_solicitud, tipo_unidad, closeModal, maniobra}){
	const queryClient = useQueryClient();

	const { data: result, status, mutate: aceptar, error } = useMutation(aceptarSolicitud, {
		onSuccess:()=>(queryClient.invalidateQueries(SOLICITUD.name),closeModal())
	});

	const actionsUnidades = crudFunctions(UNIDAD);
	const {data:unidades} = useQuery(UNIDAD.name, actionsUnidades.getAll);

	const actionsConductores = crudFunctions(CONDUCTOR);
	const {data:conductores} = useQuery(CONDUCTOR.name, actionsConductores.getAll);

	const [data, setData] = useState({});


	return <>
		<div className="flex flex-col gap-5 items-center">
			<div className="text-align it text-blue font-black text-2xl">Asigna la unidad y el conductor para el viaje</div>
			<div className="w-full">
				<RefSelect label="Unidad" dataType={UNIDAD} accessor="placas" value={data.id_unidad} onChange={v=>setData(d=>({...d, id_unidad:v}))} />
			</div>
			<div className="w-full">
				<RefSelect label="Conductor" accessors={["nombre", "apellido_p"]} value={data.id_conductor} onChange={v=>setData(d=>({...d, id_conductor:v}))} dataType={CONDUCTOR} />
			</div>
			
			{status==="error" && 	<Alert severity="error">{error?.errmsg}</Alert>}
			<button className={`rounded-3xl bg-orange text-white w-40 px-5 py-2 `} onClick={()=>aceptar({...data, id_solicitud, maniobra})}>Aceptar</button>
		</div>
		
	</>;
}